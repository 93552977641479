import {DISCONNECT, LOGIN} from "./actions";

const initialState = {
    jwt: undefined,
};

function rootReducer(state = initialState, action) {

    switch (action.type) {

        case LOGIN:
            return {
                ...initialState,
                jwt: action.jwt,
            };
        case DISCONNECT:
            return {
                ...initialState
            };
        default:
            return state;
    }
}

export default rootReducer;
