import React from "react";
import {connect} from "react-redux";
import tw from "twin.macro";
import {FaRegImage, FaComments, FaUserFriends, FaUserTie} from 'react-icons/fa';
import StatsService from "../services/stats.service";
import {toastError} from "./common/Toast";

const Item = tw.div`p-3 w-48 h-24 flex items-center justify-center text-white`;
const Number = tw.div`ml-5 text-4xl`;

class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: {
                nbComment: "-",
                nbCustomer: "-",
                nbPublication: "-",
                nbSubscriber: "-",
            }
        }
    }

    componentDidMount() {

        StatsService.getStats()
            .then(resp => {
                this.setState({
                    data: resp.data
                });
            })
            .catch(err => {
                toastError("Impossible de récupérer les données");
            });
    }

    render() {

        return (
            <div className="lg:flex">
                <div className={"flex-1 flex space-x-3 space-y-3 flex-wrap items-center h-56 py-5"}>
                    <Item className={"bg-teal-700 mt-3 ml-3"}>
                        <FaUserFriends size={"3em"}/>
                        <Number>{this.state.data.nbCustomer}</Number>
                    </Item>
                    <Item className={"bg-teal-800"}>
                        <FaUserTie size={"3em"}/>
                        <Number>{this.state.data.nbSubscriber}</Number>
                    </Item>
                    <Item className={"bg-blue-700"}>
                        <FaRegImage size={"3em"}/>
                        <Number>{this.state.data.nbPublication}</Number>
                    </Item>
                    <Item className={"bg-blue-800"}>
                        <FaComments size={"3em"}/>
                        <Number>{this.state.data.nbComment}</Number>
                    </Item>
                </div>
                <iframe className={"flex-1 min-h-screen py-5 w-full"} src={"https://iris.monitoring.wm-dev.ch/"}/>
            </div>
        )

    }
}

export default connect(null, null)(Dashboard);
