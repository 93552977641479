import React, {Suspense} from 'react';
import './App.css';
import {connect, Provider} from "react-redux";
import {persistor, store} from "./redux/store";
import {PersistGate} from 'redux-persist/integration/react'
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CustomRouter from "./pages/common/CustomRouter";

class App extends React.Component {

    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Suspense fallback="loading">
                        <ToastContainer/>
                        <CustomRouter/>
                    </Suspense>
                </PersistGate>
            </Provider>
        )
    }

}


export default App;
