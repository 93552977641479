export const LOGIN = "LOGIN";
export const DISCONNECT = "DISCONNECT";

export const login = (jwt) => ({
    type: LOGIN,
    jwt: jwt,
});

export const disconnect = () => ({
    type: DISCONNECT
});

