export default {
    baseApiUrl: process.env.REACT_APP_API_URL,
    env: process.env.REACT_APP_ENV,
    adminLogin: '/admin/login',
    listPromo: '/admin/promocode',
    listSubscriber: '/admin/subscribers',
    listCustomer: '/admin/customers',
    generateMagazine: '/admin/magazineSample/',
    regenerateMagazine: '/admin/magazine/regenerate/',
    getMagazinePages: '/admin/magazinePages',
    deleteMagazinePages: '/admin/magazinePage/',
    addMagazinePage: '/admin/magazinePage',
    getMagazines: '/admin/magazines',
    setMagazinePdf: '/magazine/pdf/',
    stats: '/admin/stats',
}
