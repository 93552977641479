import React from "react";
import {connect} from "react-redux";
import MagazineService from "../services/magazine.service";
import {toastError, toastSuccess} from "./common/Toast";
import {FaTimes} from 'react-icons/fa';
import DataTable from "react-data-table-component";

class Magazine extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            addLoading: false,
            generating: false,
            subGenerating: "",
            pageNumber: 1,
            subs: "",
            pages: [],
            progress: 0,
            magazines: [],
            magazineId: "",
            pdfSending: false
        };

        this.fileInput = React.createRef();
        this.filePdfInput = React.createRef();

        this.columns = [
            {
                name: 'Magazine ID',
                selector: 'magazineId',
                sortable: true,
            },
            {
                name: 'Ouvert',
                cell: row => row.open ? "Oui" : "Non",
                sortable: true,
            },
            {
                name: 'Abonné',
                selector: 'subscriberStr',
                sortable: true,
            },
            {
                name: 'Nombre publication',
                selector: 'nbPublications',
                sortable: true,
            },
            {
                name: 'PDF',
                cell: row => !row.error && row.path !== null ?
                    <a href={row.path} target={"_blank"} className={"text-blue-800"}>Ouvrir</a> : null,
            }, {
                name: 'Erreur',
                cell: row => row.path !== null && row.error ? <div className={"text-center"}>
                    <div className={"font-bold text-xl"}>Oui</div>
                    <a
                        onClick={() => this.reGenerateMagazine(row.magazineId)}
                        className={"text-blue-400 cursor-pointer text-sm"}
                    >
                        Regénérer le magazine
                    </a>
                </div> : row.path !== null ? <div className={"text-center"}>
                    <div>Non</div>
                    <a
                        onClick={() => this.reGenerateMagazine(row.magazineId)}
                        className={"text-blue-400 cursor-pointer"}
                    >
                        Regénérer le magazine
                    </a>
                </div> : '',
            },
        ];
    }

    componentDidMount() {
        this.fetchPages();
        this.loadMagazines();
    }

    reGenerateMagazine = (magID) => {
        MagazineService.regenerateMagazine(magID)
            .then(resp => {
                this.loadMagazines();
            })
            .catch(err => {
                toastError("Impossible de regénérer le magazine");
            })
    }

    fetchPages = () => {
        MagazineService.getCurrentPages()
            .then(resp => {
                this.setState({
                    pages: resp.data
                })
            })
            .catch(err => {
                toastError("Impossible de récupérer les pages en cours");
            })
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({addLoading: true});
        //Progress is only between front and back but not between back and obj storage
        MagazineService
            .putNewPage(
                this.fileInput.current.files[0],
                this.state.pageNumber,
                this.state.subs,
                (uploadProgress) => {
                    this.setState({
                        progress: uploadProgress
                    });
                }
            )
            .then(resp => {
                this.setState({addLoading: false});
                toastSuccess("Page ajoutée avec succès");
                this.fetchPages();
            })
            .catch(err => {
                this.setState({addLoading: false});
                toastError("Erreur");
            });
    }

    generateMagazine = (event) => {
        event.preventDefault();

        this.setState({generating: true});
        MagazineService.createMagazineSample(this.state.subGenerating)
            .then(response => {
                let blob = new Blob([response.data], {type: 'application/pdf'});
                let url = window.URL.createObjectURL(blob);
                window.open(url);
                this.setState({generating: false});
            })
            .catch(err => {
                this.setState({generating: false});
                toastError("Erreur");
            })
    }

    deleteImage = (id) => {
        if (window.confirm("Voulez-vous vraiment supprimer cette page ?")) {
            MagazineService
                .deleteMagazinePage(id)
                .then(resp => {
                    toastSuccess("Page supprimée avec succès");
                    this.fetchPages();
                })
                .catch(err => {
                    toastError("Impossible de supprimer la page");
                });
        }
    }

    pageChange = (event) => {
        this.setState({
            pageNumber: event.target.value
        });
    }

    updateSub = (event) => {
        this.setState({
            subs: event.target.value
        });
    }

    loadMagazines = () => {
        MagazineService.getMagazines()
            .then(resp => {
                this.setState({
                    magazines: resp.data
                })
            })
            .catch(err => {
                toastError("Impossible charger les magazines");
            })
    }

    replacePdf = (event) => {
        event.preventDefault();

        this.setState({pdfSending: true});
        MagazineService
            .putNewPDF(this.filePdfInput.current.files[0], this.state.magazineId)
            .then(resp => {
                this.setState({pdfSending: false});
                toastSuccess("PDF mis à jour avec succès");
                this.loadMagazines();
            })
            .catch(err => {
                this.setState({pdfSending: false});
                toastError("Erreur");
            });
    }

    downloadTextFile = () => {
        const urls = this.state.magazines.map(mag => mag.path === null ? "" : mag.path + "\n");
        let blob = new Blob(urls, {type: 'text/plain;charset=utf-8'});
        let url = window.URL.createObjectURL(blob);
        window.open(url);
    }


    render() {

        return (
            <div className="p-10">
                <h2 className="text-xl">Pages du magazine</h2>
                <form onSubmit={this.handleSubmit} className={"flex-col"}>
                    <label className="mt-4 flex-1">
                        <span className="text-gray-800">Page n°</span>
                        <select className="form-select mt-1" onChange={ev => this.pageChange(ev)}
                                value={this.state.pageNumber}>
                            {
                                [1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20].map(val => {
                                    return <option key={val} value={val}>{val}</option>
                                })
                            }
                        </select>
                    </label>
                    <label className="mt-4 ml-4 flex-1">
                        <span className="text-gray-800">Abonnés (id), vide si tout le monde :</span>
                        <input
                            type="text"
                            onChange={ev => this.updateSub(ev)}
                            value={this.state.subs}
                            className="form-input mt-1 border-1 ml-2"
                            placeholder="2,3,85"
                        />
                    </label>
                    <label className="mt-4 ml-4 flex-1">
                        <span className="text-gray-800">Fichier :</span>
                        <input
                            type="file"
                            accept="image/jpeg,image/jpg"
                            className="form-input mt-1"
                            placeholder="2,3,85"
                            ref={this.fileInput}
                        />
                    </label>
                    <button
                        type={"submit"}
                        className={"bg-blue-800 hover:bg-blue-600 text-white px-3 py-2 rounded-md text-sm font-medium"}>
                        {
                            this.state.addLoading ? "En cours..." : "Envoyer"
                        }
                    </button>
                </form>
                <div className={"flex mt-12"}>
                    {
                        this.state.pages.map(page => {
                            return (
                                <div key={page.magazinePageId} className={"w-56 inline-block m-2 relative"}>
                                    <FaTimes className={"absolute w-5 h-5 top-0 left-0 text-red-700"}
                                             onClick={() => this.deleteImage(page.magazinePageId)}/>
                                    <img src={page.file} className={"w-56"}/>
                                    <h4 className={"text-lg"}>Page n°{page.pageNumber}</h4>
                                    {
                                        page.subscribers === null ? (
                                            <p>Tout le monde</p>
                                        ) : page.subscribers.map(sub => {
                                            return (
                                                <p key={sub}>{sub}</p>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div className={"pt-10"}>
                    <h2 className="text-xl">Test d'un magazine</h2>
                    <form onSubmit={this.generateMagazine} className={"flex-col"}>
                        <label className="mt-4 ml-4 flex-1">
                            <span className="text-gray-800">ID Abonné :</span>
                            <input
                                type="text"
                                onChange={ev => this.setState({subGenerating: ev.target.value})}
                                value={this.state.subGenerating}
                                className="form-input mt-1 border-1 ml-2"
                            />
                        </label>
                        <button
                            type={"submit"}
                            className={"bg-blue-800 hover:bg-blue-600 text-white px-3 py-2 rounded-md text-sm font-medium"}>
                            {
                                this.state.generating ? "En cours..." : "Générer"
                            }
                        </button>
                    </form>
                </div>
                <div className={"pt-10"}>
                    <h2 className="text-xl">Magazines du mois</h2>
                    <DataTable
                        columns={this.columns}
                        data={this.state.magazines}
                        pagination={true}
                    />
                </div>
                <div className={"pt-10"}>
                    <h2 className="text-xl">Remplacer un pdf</h2>
                    <form onSubmit={this.replacePdf} className={"flex-col"}>
                        <label className="mt-4 ml-4 flex-1">
                            <span className="text-gray-800">Magazine ID :</span>
                            <input
                                type="text"
                                onChange={ev => this.setState({magazineId: ev.target.value})}
                                value={this.state.magazineId}
                                className="form-input mt-1 border-1 ml-2"
                            />
                        </label>
                        <label className="mt-4 ml-4 flex-1">
                            <span className="text-gray-800">Fichier :</span>
                            <input
                                type="file"
                                accept="application/pdf"
                                className="form-input mt-1"
                                ref={this.filePdfInput}
                            />
                        </label>
                        <button
                            type={"submit"}
                            className={"bg-blue-800 hover:bg-blue-600 text-white px-3 py-2 rounded-md text-sm font-medium"}>
                            {
                                this.state.pdfSending ? "En cours..." : "Envoyer"
                            }
                        </button>
                    </form>
                </div>
                <div className={"pt-10"}>
                    <h2 className="text-xl">Fichier pour l'imprimeur</h2>
                    <button
                        onClick={this.downloadTextFile}
                        className={"bg-blue-800 hover:bg-blue-600 text-white px-3 py-2 rounded-md text-sm font-medium"}>
                        Télécharger
                    </button>
                </div>
            </div>
        )
    }
}

export default connect(null, null)(Magazine);
