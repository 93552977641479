import Constants from "../Constants";
import {store} from "../redux/store";

const axios = require('axios');

class CustomerService {

    getHeaders() {
        const token = store.getState().jwt;
        return {
            headers: {Authorization: `Bearer ${token}`}
        };
    }

    findAll() {
        return axios
            .get(Constants.baseApiUrl + Constants.listCustomer,
                this.getHeaders()
            );
    }

}

export default new CustomerService();
