import React from "react";
import {connect} from "react-redux";
import PromoService from "../services/promo.service";
import {toastError} from "./common/Toast";
import DataTable from "react-data-table-component";

class PromoCode extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true
        };

        this.columns = [
            {
                name: 'Code',
                selector: 'code',
                sortable: true,
            },
            {
                name: 'Montant',
                selector: 'amount',
                sortable: true,
            },
            {
                name: 'Type',
                selector: 'promoType',
                sortable: true,
            },
            {
                name: 'Disponible',
                selector: 'available',
                sortable: true,
            },
            {
                name: 'Utilisé',
                selector: 'used',
                sortable: true,
            }, {
                name: 'Expiration',
                selector: 'expiration',
                sortable: true,
            },
        ];
    }

    componentDidMount() {
        PromoService.findAll()
            .then(resp => {
                this.setState({
                    data: resp.data,
                    loading: false
                });
            })
            .catch(err => {
                toastError("Impossible de récupérer les données");
                this.setState({
                    loading: false
                });
            });
    }

    render() {
        return (
            <div>
                <DataTable
                    title={"Codes promos"}
                    columns={this.columns}
                    data={this.state.data}
                    pagination={true}
                />
            </div>
        )

    }
}

export default connect(null, null)(PromoCode);
