import React from "react";
import {connect} from "react-redux";
import SubscriberService from "../services/subscriber.service";
import {toastError} from "./common/Toast";
import DataTable from "react-data-table-component";

class Subscriber extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true
        };

        this.columns = [
            {
                name: 'Id',
                selector: 'subscriberId',
                sortable: true,
            },
            {
                name: 'Photo',
                cell: row => <img src={row.image} className={"rounded-full h-12"}/>,
                sortable: true,
            },
            {
                name: 'Prénom',
                selector: 'firstName',
                sortable: true,
            },
            {
                name: 'Nom',
                selector: 'lastName',
                sortable: true,
            },
            {
                name: 'Adresse',
                cell: row => row.address + " " + row.zipCode + " " + row.city + " " + row.canton + " " + row.country,
                sortable: true,
            },
            {
                name: 'Taille famille',
                selector: 'nbFamilyMember',
                sortable: true,
            }, {
                name: 'Magazines créés',
                selector: 'nbMagazines',
                sortable: true,
            }, {
                name: 'Magazines à venir',
                selector: 'paidMagazine',
                sortable: true,
            }, {
                name: 'Préférences',
                cell: row => this.customPref(row),
                sortable: true,
            },
        ];
    }

    componentDidMount() {
        SubscriberService.findAll()
            .then(resp => {
                this.setState({
                    data: resp.data,
                    loading: false
                });
            })
            .catch(err => {
                toastError("Impossible de récupérer les données");
                this.setState({
                    loading: false
                });
            });
    }

    customPref(row) {
        const pref = row.magazinePref;
        let returnStr = ""

        if (pref != null) {
            if (pref.international !== "REDAC") {
                returnStr += "International : "
                pref["internationalCHOICE" + pref.internationalCHOICE]
                    .forEach(element => {
                        if (element === "OTHER") {
                            returnStr += pref["internationalCHOICEOTHER" + pref.internationalCHOICE] + " ";
                        } else {
                            returnStr += element + " ";
                        }
                    });
                returnStr += " | ";
            } else {
                returnStr += "International : REDAC | ";
            }
            if (pref.national !== "REDAC") {
                returnStr += "National : " + pref["nationalCHOICE"] + " | ";
            } else {
                returnStr += "National : REDAC | ";
            }
            if (pref.local !== "REDAC") {
                returnStr += "Local : " + pref["localCANTON"];
            } else {
                returnStr += "Local : REDAC";
            }
        }

        return returnStr;
    }

    render() {
        return (
            <div>
                <DataTable
                    title={"Abonnés"}
                    columns={this.columns}
                    data={this.state.data}
                    pagination={true}
                />
            </div>
        )

    }
}

export default connect(null, null)(Subscriber);
