import Constants from "../Constants";

const axios = require('axios');

class LoginService {


    login(email, password) {
        return axios
            .post(Constants.baseApiUrl + Constants.adminLogin, {
                username: email,
                password: password
            });
    }

}

export default new LoginService();
