import React from "react";
import {connect} from "react-redux";
import irisLogo from "../../images/iris_logo_violet.png";
import tw from "twin.macro";
import {Link} from "react-router-dom";
import {disconnect} from "../../redux/actions";

const MenuItem = tw.a`text-gray-900 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium`;

class Header extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {

        return (
            <div className="relative bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 border-b-2">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <img className="h-8" src={irisLogo} alt="Logo"/>
                            </div>
                            <div>
                                <div className="ml-10 flex items-baseline space-x-4">
                                    <MenuItem>
                                        <Link to={"/dashboard"}>
                                            Dashboard
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link to={"/customer"}>
                                            Clients
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link to={"/subscriber"}>
                                            Abonnés
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link to={"/magazine"}>
                                            Magazine
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <Link to={"/promo"}>
                                            Codes promos
                                        </Link>
                                    </MenuItem>
                                    <MenuItem className={"bg-gray-700"}>
                                        <a className={"text-white hover:text-gray-500"} onClick={this.props.disconnect}>
                                            Déconnexion
                                        </a>
                                    </MenuItem>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )

    }
}

const mapDispatchToProps = dispatch => {
    return {
        disconnect: () => dispatch(disconnect())
    }
};


export default connect(null, mapDispatchToProps)(Header);
