import React from "react";
import {connect} from "react-redux";
import CustomerService from "../services/customer.service";
import {toastError} from "./common/Toast";
import DataTable from "react-data-table-component";

class Customer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loading: true
        };

        this.columns = [
            {
                name: 'Id',
                selector: 'customerId',
                sortable: true,
            },
            {
                name: 'Photo',
                cell: row => <img src={row.picture} className={"rounded-full h-12"}/>,
                sortable: true,
            },
            {
                name: 'Prénom',
                selector: 'firstName',
                sortable: true,
            },
            {
                name: 'Nom',
                selector: 'lastName',
                sortable: true,
            },
            {
                name: 'Email',
                selector: 'email',
                sortable: true,
                wrap: true
            },
            {
                name: 'Adresse',
                selector: 'address',
                cell: row => this.removeNull(row.address + " " + row.zipCode + " " + row.city + " " + row.canton + " " + row.country),
            },
            {
                name: 'Téléphone',
                selector: 'phone',
                cell: row => this.removeNull(row.phone1 + " " + row.phone2),
            },
            {
                name: 'Abonnés ID',
                selector: 'subID',
                cell: row => row.subscribers !== null ? row.subscribers.join(', ') : '',
            },
            {
                name: 'Date création',
                selector: 'registerDate',
                sortable: true,
                wrap: true
            }
        ];
    }

    componentDidMount() {
        CustomerService.findAll()
            .then(resp => {
                this.setState({
                    data: resp.data,
                    loading: false
                });
            })
            .catch(err => {
                toastError("Impossible de récupérer les données");
                this.setState({
                    loading: false
                });
            });
    }

    removeNull = (text) => {
        return text.replaceAll("null", "");
    }

    render() {
        return (
            <div>
                <DataTable
                    title={"Clients"}
                    columns={this.columns}
                    data={this.state.data}
                    pagination={true}
                />
            </div>
        )

    }
}

export default connect(null, null)(Customer);
