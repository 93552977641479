import React from "react";
import {connect} from "react-redux";
import Header from "./common/Header";
import {Route, Switch} from "react-router-dom";
import PromoCode from "./PromoCode";
import tw from "twin.macro";
import Dashboard from "./Dashboard";
import Subscriber from "./Subscriber";
import Magazine from "./Magazine";
import Customer from "./Customer";

const Container = tw.div`px-20`;

class Home extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        const {match} = this.props;

        return (
            <div>
                <Header/>
                <Container>
                    <Switch>
                        <Route path={`${match.path}dashboard`}>
                            <Dashboard/>
                        </Route>
                        <Route path={`${match.path}subscriber`}>
                            <Subscriber/>
                        </Route>
                        <Route path={`${match.path}customer`}>
                            <Customer/>
                        </Route>
                        <Route path={`${match.path}magazine`}>
                            <Magazine/>
                        </Route>
                        <Route path={`${match.path}promo`}>
                            <PromoCode/>
                        </Route>
                        <Route path={`${match.path}`}>
                            <Dashboard/>
                        </Route>
                    </Switch>
                </Container>
            </div>
        )

    }
}

export default connect(null, null)(Home);
