import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Login from "../Login";
import Home from "../Home";
import {connect} from "react-redux";
import jwt_decode from "jwt-decode";

class CustomRouter extends React.Component {

    render() {
        return (
            <Router>
                <Switch>
                    {
                        this.props.jwt !== undefined && (jwt_decode(this.props.jwt).exp * 1000) > new Date() ? (
                            <Route path="/" component={Home}/>
                        ) : (
                            <div>
                                <Route path="/login" component={Login}/>
                                <Route path="/" component={Login}/>
                            </div>
                        )
                    }
                </Switch>
            </Router>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        jwt: state.jwt,
    }
};

export default connect(mapStateToProps, null)(CustomRouter);
