import Constants from "../Constants";
import {store} from "../redux/store";

const axios = require('axios');

class MagazineService {

    getHeaders() {
        const token = store.getState().jwt;
        return {
            headers: {Authorization: `Bearer ${token}`},
        };
    }

    createMagazineSample(subId) {
        return axios
            .post(Constants.baseApiUrl + Constants.generateMagazine + subId, {},
                {...this.getHeaders(), responseType: 'arraybuffer'}
            );
    }

    putNewPage(file, pageNumber, subs, uploadProgress) {
        const bodyFormData = new FormData();
        bodyFormData.append('file', file);
        bodyFormData.append('pageNumber', pageNumber);
        bodyFormData.append('subs', subs);

        return axios({
            method: 'put',
            url: Constants.baseApiUrl + Constants.addMagazinePage,
            data: bodyFormData,
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                uploadProgress(percentCompleted);
            },
            headers: {...this.getHeaders().headers, 'Content-Type': 'multipart/form-data'}
        });
    }

    putNewPDF(file, magazineID) {
        const bodyFormData = new FormData();
        bodyFormData.append('file', file);

        return axios({
            method: 'post',
            url: Constants.baseApiUrl + Constants.setMagazinePdf + magazineID,
            data: bodyFormData,
            headers: {...this.getHeaders().headers, 'Content-Type': 'multipart/form-data'}
        });
    }

    deleteMagazinePage(magazinePageId) {
        return axios
            .delete(Constants.baseApiUrl + Constants.deleteMagazinePages + magazinePageId, this.getHeaders());
    }

    getCurrentPages() {
        return axios
            .get(Constants.baseApiUrl + Constants.getMagazinePages,
                this.getHeaders()
            );
    }

    getMagazines() {
        return axios
            .get(Constants.baseApiUrl + Constants.getMagazines,
                this.getHeaders()
            );
    }

    regenerateMagazine(magId) {
        return axios
            .post(Constants.baseApiUrl + Constants.regenerateMagazine + magId, {},
                this.getHeaders()
            );
    }

}

export default new MagazineService();
