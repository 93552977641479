import React from "react";
import {connect} from "react-redux";
import {login} from "../redux/actions";
import LoginService from "../services/login.service";
import {toastError} from "./common/Toast";

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: ''
        }
    }

    updateField = (field, value) => {
        this.setState({
            [field]: value
        });
    }

    onSubmit = (event) => {
        event.preventDefault();
        LoginService.login(this.state.email, this.state.password)
            .then(resp => {
                this.props.login(resp.data.token);
            })
            .catch(err => {
                toastError("Connexion impossible");
            })
    }

    render() {

        return (
            <div className="min-h-screen flex flex-col items-center justify-center bg-gray-300">
                <div
                    className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
                    <div className="font-medium self-center text-xl sm:text-2xl text-gray-800">Mag-iris Admin
                    </div>
                    <form
                        onSubmit={this.onSubmit}
                    >
                        <div className="mt-10">
                            <div className="flex flex-col mb-6">
                                <label htmlFor="email"
                                       className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Email</label>
                                <div className="relative">
                                    <div
                                        className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                                        <svg className="h-6 w-6" fill="none" strokeLinecap="round"
                                             strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                             stroke="currentColor">
                                            <path
                                                d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"/>
                                        </svg>
                                    </div>
                                    <input id="email" type="email" name="email"
                                           className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                                           placeholder="Adresse email"
                                           value={this.state.email}
                                           required={"required"}
                                           onChange={(e) => this.updateField("email", e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col mb-6">
                                <label htmlFor="password"
                                       className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">Mot de
                                    passe</label>
                                <div className="relative">
                                    <div
                                        className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                                  <span>
                                    <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round"
                                         strokeWidth="2"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                      <path
                                          d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"/>
                                    </svg>
                                  </span>
                                    </div>

                                    <input id="password" type="password" name="password"
                                           className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                                           placeholder="Password"
                                           value={this.state.password}
                                           required={"required"}
                                           onChange={(e) => this.updateField("password", e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="flex w-full">
                                <button type="submit"
                                        className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-600 hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in">
                                    <span className="mr-2">Connexion</span>
                                    <span>
                                      <svg className="h-6 w-6" fill="none" strokeLinecap="round"
                                           strokeLinejoin="round" strokeWidth="2"
                                           viewBox="0 0 24 24" stroke="currentColor">
                                        <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                      </svg>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )

    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: (jwt) => dispatch(login(jwt))
    }
};

export default connect(null, mapDispatchToProps)(Login);
